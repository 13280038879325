

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Navbar.css */
.hover\:glow-neon:hover {
  text-decoration: underline;
  color: #00faff;
  position: relative;
}

.hover\:glow-neon:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #00faff;
  animation: glow 1s infinite alternate;
}

@keyframes glow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  color: black;
  background-color: white;
  border: 2px solid gray;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.btn:hover {
  border-color: white;
  box-shadow: 0 0 10px white;
}


.btnmail {
  display: inline-block;
  padding: 10px 20px;
  color: white;
  background-color: black;
  border: 2px solid gray;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.btn-email:hover {
  border-color: white;
}